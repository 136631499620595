ElementFactTableColumn.vue<template>
  <component
    :is="scaleFactTemplate"
    fact-prop-name="fact_functional"
    :disabled="!isFieldAvailableForEdit(scope.row, 'scale.fact_functional')"
    :data="scope.row.scale"
    @change="saveElementPropChange(scope.row, 'scale', {fact_functional:$event})"
  ></component>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";

export default {
  name: 'element-fact-functional-table-column',
  mixins: [ElementPropTableColumn],
  components: {ClickToEdit},

  props: {},
  data() {
    return {

    }
  },
  computed: {
    scaleFactTemplate() {
      if( !this.scope.row.scale_type ){
        return null;
      }
      return this.$companyConfiguration.scales.getScaleTemplate(this.scope.row.scale_type, 'cardFactView');
    },
  },
  methods: {}
}

</script>

<style>

</style>
